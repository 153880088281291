import { Box, Grid } from '@mui/material'
import { getSrc } from 'gatsby-plugin-image'
import * as React from 'react'
import RichText from '../RichText'


const PageHeader = ({ icon, subtitle }) => {
    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center">
            <Grid item xs={6} md={3}>
                <Box sx={{
                    height: 140,
                    background: '#F5F5F5',
                    borderRadius: '10px',
                    WebkitMaskImage: '-webkit-radial-gradient(white, black);',
                    display: 'flex',
                    alignItems: 'center',

                    justifyContent: 'center'
                }}>
                    <img
                        src={getSrc(icon)}
                        loading='eager'
                        width="100"
                        height="102"
                        alt={icon.alt ?? 'page header icon'} />
                </Box>
            </Grid>
            <Grid item xs={6} md={9}>
                <Box sx={{
                    height: 140,
                    background: '#FFF5F5',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: 2,
                    color: 'secondary.main',
                    textAlign: 'center',
                    justifyContent: 'center'
                }}>
                    <RichText
                        content={subtitle.richText}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}


export default PageHeader